<div class="row clsTitulo">
    <div class="col-10">
        <h2>Tablero de Informacion</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="far fa-chart-bar"></i>
        </div>
    </div>
</div>

<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
        <!-- Generacion de Componentes de Tipo Chica == 1-->
        <div class="card-group">
            <div *ngFor="let consulta of data">
                <div *ngIf="consulta.tipo == 1 && consulta.medida == 1">
                    <!--Grafico de Barras-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' [palettes]='consulta.colors'>
                                <e-series-collection>
                                    <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 2 && consulta.medida == 1">
                    <!--Grafico de Pie-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                                <e-accumulation-series-collection>
                                    <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true' explodeOffset='10%' [palettes]='consulta.colors'>
                                    </e-accumulation-series>
                                </e-accumulation-series-collection>
                            </ejs-accumulationchart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 3 && consulta.medida == 1">
                    <!--Grafico de Dona-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                                <e-accumulation-series-collection>
                                    <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true' explodeOffset='10%'
                                        [palettes]='consulta.colors'>
                                    </e-accumulation-series>
                                </e-accumulation-series-collection>
                            </ejs-accumulationchart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 4 && consulta.medida == 1">
                    <!--Grafico de Lineas-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [palettes]='consulta.colors'>
                                <e-series-collection>
                                    <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [width]='2' [marker]='marker'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 5 && consulta.medida == 1">
                    <!--informacion en Tabla-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="min-height: 300px;">
                            <ejs-grid [dataSource]='consulta.dato' height='100%'>
                            </ejs-grid>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 6 && consulta.medida == 1">
                    <!--Grafico de Barra y Linea-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                                <e-series-collection>
                                    <e-series *ngFor="let registro of consulta.dato; let i = index" [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 7 && consulta.medida == 1">
                    <!--Grafico de Embudo-->
                </div>
                <div *ngIf="consulta.tipo == 8 && consulta.medida == 1">
                    <!--No aplica, es para exportacion de datos-->
                </div>
                <div *ngIf="consulta.tipo == 9 && consulta.medida == 1">
                    <!--Tarjeta-->
                    <div class="card" style="width: 12rem; margin-right: 5px; margin-bottom: 5px; border-color: #424141 !important;">
                        <div class="card-header" style="background-color: #424141; color: white;flex: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" [innerHTML]="consulta.descripcion | safeHtml">
                        </div>
                        <div class="card-body" style="min-height: 70px; padding-left: 5px; padding-top: 5px;">
                            <div [innerHTML]="consulta.valor | safeHtml"></div>
                        </div>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 10 && consulta.medida == 1">
                    <!--Odometro-->
                    <div class="card border-info">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <ejs-circulargauge style='display:block;' centerY='70%'>
                            <e-axes>
                                <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270 endAngle=90>
                                    <e-pointers>
                                        <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30' radius='80%' type='RangeBar'>
                                        </e-pointer>
                                        <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9' radius='90%'></e-pointer>
                                    </e-pointers>
                                    <e-ranges>
                                        <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}' end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                                    </e-ranges>
                                </e-axis>

                            </e-axes>
                        </ejs-circulargauge>

                    </div>
                </div>
            </div>
        </div>

        <!-- Generacion de Componentes de Tipo Mediana == 2-->
        <div class="card-columns">
            <div *ngFor="let consulta of data">
                <div *ngIf="consulta.tipo == 1 && consulta.medida == 2">
                    <!--Grafico de Barras-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                                <e-series-collection>
                                    <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 2 && consulta.medida == 2">
                    <!--Grafico de Pie-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                                <e-accumulation-series-collection>
                                    <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true' explodeOffset='10%' [palettes]='consulta.colors'>
                                    </e-accumulation-series>
                                </e-accumulation-series-collection>
                            </ejs-accumulationchart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 3 && consulta.medida == 2">
                    <!--Grafico de Dona-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                                <e-accumulation-series-collection>
                                    <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true' explodeOffset='10%'
                                        [palettes]='consulta.colors'>
                                    </e-accumulation-series>
                                </e-accumulation-series-collection>
                            </ejs-accumulationchart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 4 && consulta.medida == 2">
                    <!--Grafico de Lineas-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [palettes]='consulta.colors'>
                                <e-series-collection>
                                    <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [width]='2' [marker]='marker'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 5 && consulta.medida == 2">
                    <!--informacion en Tabla-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="min-height: 300px;">
                            <ejs-grid [dataSource]='consulta.dato' height='100%'>
                            </ejs-grid>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 6 && consulta.medida == 2">
                    <!--Grafico de Barra y Linea-->
                    <div class="card border-primary">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary" style="height: 300px;">
                            <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                                <e-series-collection>
                                    <e-series *ngFor="let registro of consulta.dato; let i = index" [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 7 && consulta.medida == 2">
                    <!--Grafico de Embudo-->
                </div>
                <div *ngIf="consulta.tipo == 8 && consulta.medida == 2">
                    <!--No aplica, es para exportacion de datos-->
                </div>
                <div *ngIf="consulta.tipo == 9 && consulta.medida == 2">
                    <!--Tarjeta-->
                    <div class="card border-info">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <div class="card-body text-primary">
                            <div [innerHTML]="consulta.valor | safeHtml"></div>
                            <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="consulta.tipo == 10 && consulta.medida == 2">
                    <!--Odometro-->
                    <div class="card border-info">
                        <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                        <ejs-circulargauge style='display:block;' centerY='70%'>
                            <e-axes>
                                <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270 endAngle=90>
                                    <e-pointers>
                                        <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30' radius='80%' type='RangeBar'>
                                        </e-pointer>
                                        <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9' radius='90%'></e-pointer>
                                    </e-pointers>
                                    <e-ranges>
                                        <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}' end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                                    </e-ranges>
                                </e-axis>

                            </e-axes>
                        </ejs-circulargauge>

                    </div>
                </div>
            </div>
        </div>

        <!-- Generacion de Componentes de Tipo Grande == 3-->
        <div *ngFor="let consulta of data" style="margin-bottom: 10px;">
            <div *ngIf="consulta.tipo == 1 && consulta.medida == 3">
                <!--Grafico de Barras-->
                <div class="card border-primary">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 2 && consulta.medida == 3">
                <!--Grafico de Pie-->
                <div class="card border-primary">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                            <e-accumulation-series-collection>
                                <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true' explodeOffset='10%' [palettes]='consulta.colors'>
                                </e-accumulation-series>
                            </e-accumulation-series-collection>
                        </ejs-accumulationchart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 3 && consulta.medida == 3">
                <!--Grafico de Dona-->
                <div class="card border-primary">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                            <e-accumulation-series-collection>
                                <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true' explodeOffset='10%'
                                    [palettes]='consulta.colors'>
                                </e-accumulation-series>
                            </e-accumulation-series-collection>
                        </ejs-accumulationchart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 4 && consulta.medida == 3">
                <!--Grafico de Lineas-->
                <div class="card border-primary">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [width]='2' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 5 && consulta.medida == 3">
                <!--informacion en Tabla-->
                <div class="card border-primary">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="min-height: 300px;">
                        <ejs-grid [dataSource]='consulta.dato' height='100%'>
                        </ejs-grid>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 6 && consulta.medida == 3">
                <!--Grafico de Barra y Linea-->
                <div class="card border-primary">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato; let i = index" [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 7 && consulta.medida == 3">
                <!--Grafico de Embudo-->
            </div>
            <div *ngIf="consulta.tipo == 8 && consulta.medida == 3">
                <!--No aplica, es para exportacion de datos-->
            </div>
            <div *ngIf="consulta.tipo == 9 && consulta.medida == 3">
                <!--Tarjeta-->
                <div class="card border-info">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary">
                        <div [innerHTML]="consulta.valor | safeHtml"></div>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 10 && consulta.medida == 3">
                <!--Odometro-->
                <div class="card border-info">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <ejs-circulargauge style='display:block;' centerY='70%'>
                        <e-axes>
                            <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270 endAngle=90>
                                <e-pointers>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30' radius='80%' type='RangeBar'>
                                    </e-pointer>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9' radius='90%'>
                                    </e-pointer>
                                </e-pointers>
                                <e-ranges>
                                    <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}' end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                                </e-ranges>
                            </e-axis>

                        </e-axes>
                    </ejs-circulargauge>

                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
        <div>
            <h4 class="mb-4 text-muted">Formularios </h4>
            <div class="row">
                <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
                    <div class="card h-100">
                        <div class="card-title mb-1 p-2">
                            <h5>Formulario Basico</h5>
                        </div>
                        <div class="card-body">
                            <form>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Correo</label>
                                    <input type="email" class="form-control rounded-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Correo Electronico">
                                    <small id="emailHelp" class="form-text text-muted">Verificar su correo</small>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Password</label>
                                    <input type="password" class="form-control rounded-0" id="exampleInputPassword1" placeholder="Password">
                                </div>
                                <div class="form-group">
                                    <label for="tfFecha">Fecha</label>
                                    <ejs-datepicker id="tfFecha" [format]="format" [enableMask]="true">
                                    </ejs-datepicker>
                                </div>
                                <div class="custom-control custom-checkbox my-1 mr-sm-2 mb-3">
                                    <input type="checkbox" class="custom-control-input" id="customControlInline">
                                    <label class="custom-control-label" for="customControlInline">Recordar</label>
                                </div>
                                <button type="button" class="btn btn-guardar rounded-0 mb-2 mr-2">Guardar</button>
                                <button type="button " class="btn btn-cancelar rounded-0 mb-2">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
                    <div class="card h-100">
                        <div class="card-title mb-1 p-2">
                            <h5>Formulario tabs</h5>
                        </div>
                        <div class="card-content">
                            <!-- Seccion de pestañas Inicia-->
                            <!-- Seccion de Botones de pestañas -->
                            <ul class="nav nav-tabs" role="tablist" style="background-color: #fff; z-index: 999;">
                                <li class="nav-item">
                                    <a class="nav-link active" id="tab-01" data-toggle="tab" href="#tab01" role="tab" aria-controls="tab01" aria-selected="true">Pestaña 1</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="tab-02" data-toggle="tab" href="#tab02" role="tab" aria-controls="tab02" aria-selected="false">Pestaña 2</a>
                                </li>
                            </ul>
                            <!-- Seccion de contenido de tabs -->
                            <div class="tab-content" style="border: 1px solid #ccc; padding: 5px 10px !important;">
                                <div class="tab-pane fade show active" id="tab01" role="tabpanel" aria-labelledby="tab01">
                                    <p>
                                        Ejemplo de como cargar un formulario dentro de una sección o pestaña o solapa.
                                    </p>
                                    <form>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Correo</label>
                                            <input type="email" class="form-control rounded-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Correo Electronico">
                                            <small id="emailHelp" class="form-text text-muted">Verificar su
                                                correo</small>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Password</label>
                                            <input type="password" class="form-control rounded-0" id="exampleInputPassword1" placeholder="Password">
                                        </div>
                                        <div class="form-group">
                                            <label for="tfFecha">Fecha</label>
                                            <ejs-datepicker id="tfFecha" [format]="format" [enableMask]="true">
                                            </ejs-datepicker>
                                        </div>
                                        <div class="custom-control custom-checkbox my-1 mr-sm-2 mb-3">
                                            <input type="checkbox" class="custom-control-input" id="customControlInline">
                                            <label class="custom-control-label" for="customControlInline">Recordar</label>
                                        </div>
                                        <button type="button" class="btn btn-guardar rounded-0 mb-2 mr-2">Guardar</button>
                                        <button type="button " class="btn btn-cancelar rounded-0 mb-2">Cancel</button>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="tab02" role="tabpanel" aria-labelledby="tab02">
                                    <p>
                                        Est quis nulla laborum officia ad nisi ex nostrud culpa Lorem excepteur aliquip dolor aliqua irure ex. Nulla ut duis ipsum nisi elit fugiat commodo sunt reprehenderit laborum veniam eu veniam. Eiusmod minim exercitation fugiat irure ex labore incididunt
                                        do fugiat commodo aliquip sit id deserunt reprehenderit aliquip nostrud. Amet ex cupidatat excepteur aute veniam incididunt mollit cupidatat esse irure officia elit do ipsum ullamco Lorem. Ullamco ut ad minim do
                                        mollit labore ipsum laboris ipsum commodo sunt tempor enim incididunt. Commodo quis sunt dolore aliquip aute tempor irure magna enim minim reprehenderit. Ullamco consectetur culpa veniam sint cillum aliqua incididunt
                                        velit ullamco sunt ullamco quis quis commodo voluptate. Mollit nulla nostrud adipisicing aliqua cupidatat aliqua pariatur mollit voluptate voluptate consequat non.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <p>Informacion de pie de pagina</p>
                        </div>
                    </div>
                    <!-- Seccion de pestañas Termina-->
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-4 ">
                    <div class="card">
                        <div class="card-title mb-1 p-2">
                            <h5>Formulario con elementos de catalogos</h5>
                        </div>
                        <div class="card-content">
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text rounded-0" id="">Cuenta Contable</span>
                                    </div>
                                    <button type="button" class="btn btn-buscar rounded-0"></button>
                                    <input type="text" class="form-control rounded-0" readonly='true' value='1000-100-100' style="max-width: 150px;">
                                    <input type="text" class="form-control rounded-0" readonly='true' value='Nombre de la cuenta contable'>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text rounded-0" id="">Empleado</span>
                                    </div>
                                    <button type="button" class="btn btn-buscar rounded-0"></button>
                                    <input type="text" class="form-control rounded-0" readonly='true' value='Elizandro Carballo Developer'>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text rounded-0" id="">Cliente</span>
                                    </div>
                                    <button type="button" class="btn btn-buscar rounded-0"></button>
                                    <input type="text" class="form-control rounded-0" readonly='true' value='Anfral Industries Inc'>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text rounded-0" id="">Proveedor</span>
                                    </div>
                                    <button type="button" class="btn btn-buscar rounded-0"></button>
                                    <input type="text" class="form-control rounded-0" readonly='true' value='XOOK Mexico SA de CV'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 mb-4 ">
                    <div class="card">
                        <div class="card-title mb-1 p-2">
                            <h5>Formulario con Elementos <i>Basicos</i></h5>
                        </div>
                        <div class="card-body">
                            <form>
                                <div class="row" style="display: flex;">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleFormControlInput1">Correo Electrónico</label>
                                            <input type="email" class="form-control  rounded-0" id="exampleFormControlInput1" placeholder="name@example.com">
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Listado de Opciones</label>
                                            <ejs-combobox id='comboelement' [dataSource]='dataCombobox' placeholder='Select a game'></ejs-combobox>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Seleccionar archivo</label>
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="customFile">
                                                <label class="custom-file-label rounded-0" for="customFile">Carga
                                                    Archivo</label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="tfFecha">Fecha</label>
                                            <ejs-datepicker id="tfFecha" [format]="format" [enableMask]="true">
                                            </ejs-datepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="display: flex;">
                                    <div class="form-group col-md-6">
                                        <label for="exampleFormControlSelect2">Multiseleccion</label>
                                        <ejs-listview #lstMultiseleccion [dataSource]='dataCheckbox' [fields]='fieldsCheckbox' [showCheckBox]='true' headerTitle='Listado Multiseleccion' showHeader='true'>
                                        </ejs-listview>
                                        <p>Es importante considerar enviar un array de objetos del tipo
                                            <strong>Modelo</strong>. De esta manera lo pueden gestionar mas fácil en la Api.
                                        </p>
                                        <button class="btn btn-procesar" (click)="getMultiseleccionItems()">
                                            Items Seleccionados
                                        </button>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="exampleFormControlTextarea1">Area de texto</label>
                                        <textarea class="form-control rounded-0" id="exampleFormControlTextarea1" rows="3"></textarea>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <ul class="listas">
                                                    <form [formGroup]='frmRadioButom'>
                                                        <li class="listas">
                                                            <ejs-radiobutton label="Opcion 01" [value]='1' formControlName='opcionRadio'></ejs-radiobutton>
                                                        </li>
                                                        <li class="listas">
                                                            <ejs-radiobutton label="Opcion 02" [value]='20' formControlName='opcionRadio'></ejs-radiobutton>
                                                        </li>
                                                        <li class="listas">
                                                            <ejs-radiobutton label="Opcion 03" [value]='3' formControlName='opcionRadio'></ejs-radiobutton>
                                                        </li>
                                                    </form>
                                                </ul>
                                                <button class="btn btn-procesar" (click)="getOpcionesItems()">
                                                    Opciones Seleccionadas
                                                </button>
                                            </div>
                                            <div class="col-md-6">
                                                <ul class="listas">
                                                    <form [formGroup]='frmCheckBox'>
                                                        <li class="listas">
                                                            <ejs-checkbox label="Opcion A" formControlName='opcion_a'> </ejs-checkbox>
                                                        </li>
                                                        <li class="listas">
                                                            <ejs-checkbox label="Opcion B" formControlName='opcion_b'></ejs-checkbox>
                                                        </li>
                                                        <li class="listas">
                                                            <ejs-checkbox label="Opcion C" formControlName='opcion_c'></ejs-checkbox>
                                                        </li>
                                                    </form>
                                                </ul>
                                                <button class="btn btn-procesar" (click)="getMultiOpcionesItems()">
                                                    Opciones Seleccionadas
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="display: flex;">
                                    <div class="form-group col-md-12" style="display: flex;">
                                        <div class="col-md-6 dual-list-groupa">
                                            <h4>Grupo A</h4>
                                            <ejs-listbox [dataSource]='dataA' [toolbarSettings]='toolbarSettings' height='330px' scope='#connected-list' [fields]='fields' [noRecordsTemplate]='noRecordsTemplate' (actionComplete)="actionComplete($event)"></ejs-listbox>
                                        </div>
                                        <div class="col-md-6 dual-list-groupb">
                                            <h4>Grupo B</h4>
                                            <ejs-listbox id='connected-list' [dataSource]='dataB' height='330px' [fields]='fields' [noRecordsTemplate]='noRecordsTemplate' (actionComplete)="actionComplete($event)"></ejs-listbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <ejs-richtexteditor #texto [toolbarSettings]='tools' [insertImageSettings]='imageSettings' height='100%' (focus)='txtFocus()' (blur)='txtBlur()'>
                                        </ejs-richtexteditor>
                                    </div>
                                </div>
                                <div class="row" style="display: block;">
                                    <div class="callout callout-default">
                                        Timer y su configuracion
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label for="tfLunes">Lunes</label>
                                            <ejs-timepicker id="tfLunes" placeholder='Hora' [value]='laHora' [format]='formatString' [step]='interval' [width]='100' style="display: block; padding-right:5px;"></ejs-timepicker>
                                        </div>
                                        <div class="form-group">
                                            <label for="tfMartes">Martes</label>
                                            <ejs-timepicker id="tfMartes" placeholder='Hora' [value]='laHora' [format]='formatString' [step]='interval' [width]='100' style="display: block; padding-right:5px;"></ejs-timepicker>
                                        </div>
                                        <div class="form-group">
                                            <label for="tfMiercoles">Miercoles</label>
                                            <ejs-timepicker id="tfMartes" placeholder='Hora' [value]='laHora' [format]='formatString' [step]='interval' [width]='100' style="display: block; padding-right:5px;"></ejs-timepicker>
                                        </div>
                                        <div class="form-group">
                                            <label for="tfJueves">Jueves</label>
                                            <ejs-timepicker id="tfJueves" placeholder='Hora' [value]='laHora' [format]='formatString' [step]='interval' [width]='100' style="display: block; padding-right:5px;"></ejs-timepicker>
                                        </div>
                                        <div class="form-group">
                                            <label for="tfViernes">Viernes</label>
                                            <ejs-timepicker id="tfViernes" placeholder='Hora' [value]='laHora' [format]='formatString' [step]='interval' [width]='100' style="display: block; padding-right:5px;"></ejs-timepicker>
                                        </div>
                                        <div class="form-group">
                                            <label for="tfSabado">Sabado</label>
                                            <ejs-timepicker id="tfSabado" placeholder='Hora' [value]='laHora' [format]='formatString' [step]='interval' [width]='100' style="display: block; padding-right:5px;"></ejs-timepicker>
                                        </div>
                                        <div class="form-group">
                                            <label for="tfDomingo">Domingo</label>
                                            <ejs-timepicker id="tfDomingo" placeholder='Hora' [value]='laHora' [format]='formatString' [step]='interval' [width]='100' style="display: block; padding-right:5px;"></ejs-timepicker>
                                        </div>


                                    </div>
                                </div>

                                <div class="row" style="display: block;">
                                    <div class="callout callout-default">
                                        Barra de botones, siempre debemos re-saltar uno, que debe ser Guardar o Continuar
                                    </div>
                                    <h6>Estas son las clases para generar los botones con el color e icono</h6>
                                    <p>Deben tener la clase principal "<strong>btn</strong>"</p>
                                    <ul>
                                        <li>btn-guardar</li>
                                        <li>btn-cancelar</li>
                                        <li>btn-procesar</li>
                                        <li>btn-correo</li>
                                        <li>btn-imprimir</li>
                                        <li>btn-mensaje</li>
                                        <li>btn-copiar</li>
                                        <li>btn-editar</li>
                                        <li>btn-borrar</li>
                                        <li>btn-buscar</li>
                                    </ul>
                                    <div class="callout callout-botones">
                                        <button type="button" class="btn btn-nuevo rounded-0 mb-2 mr-2">Nuevo</button>
                                        <button type="button" class="btn btn-guardar rounded-0 mb-2 mr-2">Guardar</button>
                                        <button type="button" class="btn btn-cancelar rounded-0 mb-2">Cancel</button>
                                        <button type="button" class="btn btn-procesar rounded-0 mb-2 mr-2">Procesar</button>
                                        <button type="button" class="btn btn-correo rounded-0 mb-2 mr-2">Correo</button>
                                        <button type="button" class="btn btn-imprimir rounded-0 mb-2 mr-2">Imprimir</button>
                                        <button type="button" class="btn btn-mensaje rounded-0 mb-2 mr-2">Mensaje</button>
                                        <button type="button" class="btn btn-copiar rounded-0 mb-2 mr-2">Copiar</button>
                                        <button type="button" class="btn btn-editar rounded-0 mb-2 mr-2">Editar</button>
                                        <button type="button" class="btn btn-borrar rounded-0 mb-2 mr-2">Borrar</button>
                                        <button type="button" class="btn btn-convertir rounded-0 mb-2 mr-2">Convertir</button>
                                        <button type="button" class="btn btn-buscar rounded-0 mb-2 mr-2">Buscar</button>
                                        <button type="button" class="btn btn-buscar rounded-0 mb-2 mr-2"></button>
                                    </div>
                                    <p>Botones para utilizarse en tablas</p>
                                    <ul>
                                        <li>btn btn-editar-sm</li>
                                        <li>btn btn-borrar-sm</li>
                                    </ul>
                                    <div class="callout callout-botones">
                                        <button type="button" class="btn btn-editar-sm rounded-0 mb-2 mr-2"></button>
                                        <button type="button" class="btn btn-borrar-sm rounded-0 mb-2 mr-2"></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>