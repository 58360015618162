import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MdlMembresiaReservacion } from '../models/MdlMembresiaReservacion';

@Injectable({
  providedIn: 'root'
})
export class ReservacionService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }


  public seccion(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/reservacion/seccion`);
  }
  public division(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/reservacion/division`);
  }
  public horario(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/reservacion/horario`);
  }
  public division2(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/reservacion/division2/${id}`);
  }


  public guardar(modelo: MdlMembresiaReservacion) {
    console.log(modelo);
    return this.http
      .post(`${environment.base_url}/membresia/reservacion/guardar`, modelo)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
}
