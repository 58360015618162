import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.scss'],
})
export class PoliticasComponent implements OnInit {
  public data: any;

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  ngOnInit(): void {
    //===============================================================================================
    // creamos la conexion a mi servicio para traer el data de mi api
    // ===============================================================================================
    this.route.data.subscribe((resp) => {
      this.data = resp.listado.respuesta.aviso;
    });
  }
}
