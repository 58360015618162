import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth/auth.routing';
import { PagesRoutingModule } from './pages/pages.routing';
import { PagesComponent } from './pages/pages.component';
import { GeneralComponent } from './pages/general/general.component';
import { IntegrantesComponent } from './pages/integrantes/integrantes.component';
import { EstadocuentaComponent } from './pages/estadocuenta/estadocuenta.component';
import { OpenpayComponent } from './pages/openpay/openpay.component';
import { GeneralResolver } from './pages/general/services/general.resolver';
import { PoliticasComponent } from './pages/politicas/politicas.component';
import { TerminosComponent } from './pages/terminos/terminos.component';
import { TerminosResolver } from './pages/terminos/services/terminos.resolver';
import { AvisosResolver } from './pages/politicas/services/avisos.resolver';
import { EstadocuentaResolver } from './pages/estadocuenta/services/estadocuenta.resolver';
import { ConfirmaPagoComponent } from './pages/confirma-pago/confirma-pago.component';
import { ReservacionComponent } from './pages/reservacion/reservacion.component';
import { ReciboResolver } from './pages/openpay/services/recibo.resolver';

const routes: Routes = [
  { path: '', redirectTo: '/tablero/tablero2', pathMatch: 'full' },
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      {
        path: 'general',
        component: GeneralComponent,
        pathMatch: 'full',
        resolve: { membresia: GeneralResolver },
      },
      {
        path: 'integrantes',
        component: IntegrantesComponent,
        pathMatch: 'full',
      },
      {
        path: 'estadocuenta',
        component: EstadocuentaComponent,
        pathMatch: 'full',
        resolve: { listado: EstadocuentaResolver },
      },
      {
        path: 'politica',
        component: PoliticasComponent,
        pathMatch: 'full',
        resolve: { listado: AvisosResolver },
      },
      {
        path: 'terminos',
        component: TerminosComponent,
        pathMatch: 'full',
        resolve: { terminos: TerminosResolver },
      },
      {
        path: 'reservacion',
        component: ReservacionComponent,
        pathMatch: 'full',
      },
      {
        path: 'confirmapago',
        component: ConfirmaPagoComponent,
        pathMatch: 'full',
      },
      {
        path: 'openpay/:id',
        component: OpenpayComponent,
        pathMatch: 'full',
        resolve: {
          recibo: ReciboResolver
        },
      },
    ],
  },
  {
    path: 'tablero',
    component: PagesComponent,
    loadChildren: () =>
      import('./pages/SItablero/sitablero.module').then(
        (m) => m.SItableroModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AuthRoutingModule,
    PagesRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
