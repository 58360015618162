<div class="container">
    <div class="order-success__header">
        <app-icon class="order-success__icon" name="check-100" size="100"></app-icon>
        <div class="col-md-8 text-center">
            <div>
                <h1>¡Gracias!</h1>
            </div>
            <div class="order-success__subtitle">
                <h4>Tu orden ha sido recibida</h4>
            </div>
            <div class="row justify-content-center">

                <br>
                <h4> Has realizado tu pedido con éxito</h4>

                <h5 class="text-justify">
                    ¡Gracias por realizar tu pedido! Estamos contentos de que haya encontrado lo que estaba buscando. Nuestro objetivo es que siempre esté satisfecho.</h5>
                <h5 class="text-justify">Para confirmar fecha y hora de tu pedido favor de comunicarte al {{dataFacturacion.Telefono}}
                </h5>
                <h5>¡Que tengas un gran día!</h5>
                <p class="text-justify" style="font-size: 11px;">
                    En caso de cancelación del pedido, favor de comunicarte al {{dataFacturacion.Telefono}} con un máximo de 2 horas posteriores al haberlo realizado, pues transcurrido este tiempo, no procederá reembolso alguno. Para el caso de reembolsos, se generará un
                    porcentaje de comisión por concepto de cobro que realiza openpay por haber realizado la transacción. El método de recibo de pago o paynet no aplica en reembolsos.</p>
                <h6>Si requieres factura favor de solicitarla en:
                    <!-- <a href="{{dataFacturacion.Correo}}" -->
                    <!-- target="_top"
                        style="color: black; text-decoration: underline;"> -->
                    <strong>
                            <!-- {{dataFacturacion.Telefono}} -->
                        </strong>
                    <!-- </a> -->
                </h6>
            </div>
        </div>
    </div>
    <!-- tabla del recibo -->
    <div style="overflow-x: scroll;">
        <table class="table table-striped table-bordered table-hover table-sm">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Moneda</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Recibo" style="margin-left: 15px;">
                    <td>
                        {{item.Numero}}
                    </td>
                    <td>
                        {{item.moneda}}
                    </td>
                    <td class="columnaNumero">
                        {{item.importe}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <Strong><h5>Detalle de Compra</h5></Strong>
    <!-- tabla detalle -->
    <div style="overflow-x: scroll;">
        <table class="table table-striped table-bordered table-hover table-sm">
            <thead>
                <tr>
                    <th scope="col">Cantidad</th>
                    <th scope="col">Concepto</th>
                    <th scope="col">Importe</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of ReciboDetalle" style="margin-left: 15px;">
                    <td>
                        {{item.cantidad}}
                    </td>
                    <td>
                        {{item.descripcion}}
                    </td>
                    <td class="columnaNumero">
                        {{item.importe}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>





</div>