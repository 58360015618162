<div class="row clsTitulo">
    <div class="col-10">
        <h2>Estado de Cuenta</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="far fa-chart-bar"></i>
        </div>
    </div>
</div>
<!-- botonones encabezado -->
<div class="callout callout-primary">
    <div class="custom-control custom-checkbox my-1 mr-sm-2 mb-3">
        <!-- <input type="checkbox" #chkHistorial (change)='checkboxChange($event)' class="custom-control-input" id="chkHistorial"> -->
            <ejs-checkbox #checkbox label="CheckBox: true" [checked]="true" (change)="changeHandler()"></ejs-checkbox>
        <!-- <label class="custom-control-label" for="chkHistorial">Mostrar Todos los Adeudos</label> -->
    </div>
</div>

<!-- subtitulo tabla de adeudos -->
<div class="row clsTitulo">
    <div class="col-10">
        <h6>Tabla de Adeudos de la Membresia</h6>
    </div>
</div>

<ejs-grid #adeudos [dataSource]='AdeudoTabla' height='200' 
    (rowSelected)="onRowSelected($event)" (rowDeselected)='rowDeselected($event)'>
    <e-columns>
        <e-column type='checkbox' width='50'></e-column>
        <!-- <e-column field='id' headerText='No.' width='120' textAlign='Right'></e-column> -->
        <e-column field='fecha' type='date' headerText='fecha' width='120' format="dd/MM/yyyy" textAlign='left'></e-column>
        <e-column field='importe' headerText='importe' width='90' format='N2' textAlign='left'> </e-column>
        <e-column field='descripcion' headerText='descripcion' width='200'></e-column>
        <e-column field='estatus' headerText='estatus' width='80'></e-column>
     </e-columns>
</ejs-grid>

<!-- botonones encabezado -->
<div class="callout callout-success">
    <div class="row">
        <i class="fas fa-dollar-sign" style="font-size:28px; color: #28a745; margin-left: 80px"></i>
        <input type="number" format='C2' #importefinal class="form-control" style="width:150px; text-align:right; font-size: 28px; margin-left: 10px;" readonly='true' value="0.00">
        <button class="btn btn-aceptar" (click)="btnPagar()">Pagar</button>
    </div>
</div>