import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tablero',
  templateUrl: './tablero.component.html',
  styleUrls: ['./tablero.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class TableroComponent implements OnInit {
  public data: any;

  constructor(
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.route.data
      .subscribe((resp: { listado: any[] }) => {
        this.data = JSON.parse(JSON.stringify(resp.listado)).respuesta;
      });
  }
}