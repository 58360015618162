import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { environment } from 'src/environments/environment';
import { MdlMembresiaContacto } from './models/MdlMembresiaContacto';
import { IntegrantesService } from './services/integrantes.service';

@Component({
  selector: 'app-integrantes',
  templateUrl: './integrantes.component.html',
  styleUrls: ['./integrantes.component.scss']
})
export class IntegrantesComponent implements OnInit {
  public usuario: MdlUsuario;
  public format: string = "dd/MM/yyyy";
  public data: any;
  public columns: any;
  public id_clb_membresia: number = 1;
  public imagen;
  // public formulario: FormGroup;
  public formulario: FormGroup = new FormGroup({});
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private integrantesService: IntegrantesService,
    private domSanitizer: DomSanitizer,
  ){
        // id de la membresia localStorage se debe importar modelo en consola
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    console.log(this.usuario.id_clb_membresia);
    this.http
      .get<any>(`${environment.base_url}/membresia/contacto/cargatbcontactos/${this.usuario.id_clb_membresia}`)
      .subscribe((resp) => {
        this.data = resp.respuesta.dataSource;
        this.columns = resp.respuesta.columns;
        this.cargaContacto(this.usuario.id);

      });
      this.formulario = this.formBuilder.group(new MdlMembresiaContacto);
  }

  ngOnInit(): void {
  }

  //METODO PARA CARGAR EL CONTACTO DEL CONTACTO SELECCIONADO
  public onRowSelected(args: any) {
    this.cargaContacto(args.data.id);
  }
  //METODO PARA CARGAR EL CONTACTO ESPERANDO EL PARAMETRO ID
  cargaContacto(id: number){
    this.http
    .get<any>(`${environment.base_url}/membresia/contacto/cargacontacto/${id}`)
    .subscribe((resp) => {
      this.formulario = this.formBuilder.group(JSON.parse(JSON.stringify(resp.respuesta)) as MdlMembresiaContacto);
      this.imagen = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${resp.respuesta.imagen}`);
    });
  }

}
