import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MdlMembresia } from './models/MdlMembresia';
import { GeneralService } from './services/general.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  public formulario:  FormGroup = new FormGroup({});
  public format: string = 'dd/MM/yyyy';
  // listbox
  public lstEstadoBox: any;
  public lstMunicipioBox: any;
  public lstPaisBox: any;
  public lstClasificaBox: any;
  public lstEstatusBox: any;
  // config listbox
  public fields: Object = { text: 'descripcion', value: 'id' };

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private Services: GeneralService,


  ) {
    // lisbox carga de informacion
    this.Services.lstMunicipioBox().subscribe(resp => { this.lstMunicipioBox = resp.respuesta; });
    this.Services.lstEstadoBox().subscribe(resp => { this.lstEstadoBox = resp.respuesta; });
    this.Services.lstPaisBox().subscribe(resp => { this.lstPaisBox = resp.respuesta; });
    this.Services.lstClasificaBox().subscribe(resp => { this.lstClasificaBox = resp.respuesta; });
    this.Services.lstEstatusBox().subscribe(resp => { this.lstEstatusBox = resp.respuesta; });
    this.route.data
      .subscribe((resp: { membresia: any[] }) => {
        console.log(JSON.parse(JSON.stringify(resp.membresia)));
        this.formulario = this.formBuilder.group(JSON.parse(JSON.stringify(resp.membresia)).respuesta as MdlMembresia);
        // let membresia: MdlMembresia = resp.membresia;
        // this.formulario = this.formBuilder.group(membresia);
      });
    
  }

  ngOnInit(): void {
     
    
  }

}
