import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { EscritorioComponent } from './escritorio/escritorio.component';


const routes: Routes = [
    {
        path: 'escritorio', component: PagesComponent,
        children: [
            { path: '', component: EscritorioComponent, data: { titulo: 'Escritorio' } },
            { path: '**', component: EscritorioComponent },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule],
    providers: [

    ]
})
export class PagesRoutingModule { }
