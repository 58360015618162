import { Component, ViewChild, OnInit } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { MenuItemModel } from '@syncfusion/ej2-navigations';
import { UsuarioService } from '../auth/services/usuario.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataManager } from '@syncfusion/ej2-data';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { MdlUsuario } from '../auth/models/MdlUsuario';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent {
  @ViewChild('sidebarMenuInstance')
  public sidebarMenuInstance?: SidebarComponent;
  public width: string = '220px';
  public mediaQuery: string = '(min-width: 600px)';
  public target: string = '.main-content';
  public dockSize: string = '50px';
  public enableDock: boolean = true;
  public notificacion: any = [];
  public NotificacionPosicion: number = 1;
  public today = new Date();
  public dm: DataManager;
  public menu: { [key: string]: Object }[];
  public usuario: MdlUsuario;

  public menuItems: MenuItemModel[] = [
    { text: 'Kpi', url: '/escritorio/kpi', iconCss: 'glyphicon glyphicon-user' },
    { text: 'Dashboard 1', url: '/escritorio/Tablero1', iconCss: 'glyphicon glyphicon-dashboard' },
    { text: 'Dashboard 2', url: '/escritorio/Tablero2', iconCss: 'glyphicon glyphicon-dashboard' },
  ];

  public AccountMenuItem: MenuItemModel[] = [
    {
      text: 'Account',
      items: [{ text: 'Profile' }, { text: 'Sign out' }],
    },
  ];

  public menuFields: object = {
    text: ['category', 'value'],
    children: ['options']
  };


  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  constructor(
    private appService: AppService,
    private usuarioService: UsuarioService,
    private http: HttpClient
  ) {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {
    //=============================================================================================
    // Traemos el menu con con base en el usuasrio autentificado
    //=============================================================================================
    this.http.get<any[]>(`${environment.base_url}/auth/menu`)
      .subscribe(data => {
        this.menu = data;
      },
        error => {
          console.log('Problemas al obtener el menu: \n',
            (error.status === 404 ? 'Error 404, Problemas con la API' : error.message)
          );
          DialogUtility.alert({
            title: 'Informacion Arcarius [Error 404]',
            content: 'Error 404, Problemas con la API',
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        }
      );
    //=============================================================================================
    // Obtenemos la informacion de los mensajes del usuario
    //=============================================================================================
    const base_url = environment.base_url;
    this.http.get<any>(`${base_url}/organizacion/notificacion/listado`).subscribe(
      respuesta => {
        this.notificacion = respuesta;
      }
    );
    //=============================================================================================
  }

  cargaNotificacion() {
    const base_url = environment.base_url;
    this.http.get<any>(`${base_url}/organizacion/notificacion/listado`).subscribe(
      respuesta => {
        this.notificacion = respuesta;
      }
    );
  }

  onNotificacionChange(posicion: number): void {
    this.NotificacionPosicion = posicion;
    if (this.NotificacionPosicion == 1) {
      this.cargaNotificacion();
    }
  }

  logout(): void {
    this.usuarioService.logout();
  }
}
