import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { ConfirmacionpagoService } from './Services/Confirmacionpago.service';

@Component({
  selector: 'app-confirma-pago',
  templateUrl: './confirma-pago.component.html',
  styleUrls: ['./confirma-pago.component.scss']
})
export class ConfirmaPagoComponent implements OnInit {
  public usuario: MdlUsuario;
  public Recibo: any;
  public ReciboDetalle: any;
  public dataFacturacion: any;
  public id_membresia: any = JSON.parse(localStorage.getItem("id_clb_membresia"))
  
  constructor
  (
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private Services: ConfirmacionpagoService
  )
  {
    // id de la membresia localStorage se debe importar modelo en consola
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    console.log(this.usuario.id_clb_membresia);
    // cargamos los importes por encabezado y detalle
    this.Services.GetDatosEmpresa().subscribe(resp => { 
      this.dataFacturacion = resp.respuesta;
      // console.log(this.dataFacturacion);
    });    
    this.Services.GetReciboDetalle(668).subscribe(resp => { 
      this.ReciboDetalle = resp.respuesta;
      console.log(this.ReciboDetalle);
    });
    this.Services.GetRecibo(668).subscribe(resp => { 
      this.Recibo = resp.respuesta;
      console.log(this.Recibo);
    });
  }
  ngOnInit(): void {
    
  }
  
}  

