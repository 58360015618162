import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EstadocuentaService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  /**
   * Metodo para obtener la membresia con base en un Identificador o Numero
   * @param id Numero o Identificador de la membresia
   * @returns Observable con membresia
   */
  getAdeudoTabla(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/AdeudoTabla/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  /**
   * Metodo para obtener documento nuevo para procesar el pago.
   * @id Numero o Identificador de la membresia
   * @_ids_membresia Numero o Identificador de los documentos a pagar
   * 
   */
  public getAdeudo(id_clb_nenbresia: any, _ids_documentos: string): Observable<any> | any
    {
    const parametro: HttpParams = new HttpParams()
    .append('id', id_clb_nenbresia)
    .append('ids', _ids_documentos);
    return this.http.post<any>(`${environment.base_url}/membresia/AdeudoDocumento`, { params: parametro });
    }

    public getTabla(id_membresia: any, adeudo: any): Observable<any> | any
    {
    const parametro: HttpParams = new HttpParams()
    .append('id_membresia', id_membresia)
    .append('adeudo', adeudo);
    return this.http.get<any>(`${environment.base_url}/membresia/AdeudoTabla`, { params: parametro });
    }



}
