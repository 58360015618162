<div class="row clsTitulo">
  <div class="col-10">
    <h2>Integrantes</h2>
  </div>
  <div class="col-2">
    <div class="icono">
      <i class="far fa-chart-bar"></i>
    </div>
  </div>
</div>
<div class="tab-content">
  <form [formGroup]='formulario'>
    <div class="row">
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
        <div class="card h-100">
          <div class="card-title mb-1 p-2">
            <h5>Información General del Contacto</h5>
          </div>
          <div class="card-body">
            <div class="row" style="display: flex;">
              <div class="form-group">
                <img id="PerfilImagen" [src]="imagen" class="rounded float-right" width="100" height="100">
              </div>
            </div>
            <div class="form-group">
              <label for="nombre">Nombre</label>
              <input type="text" id="nombre" class="form-control rounded-0" placeholder="Nombre"
                formControlName='nombre' readonly>
              <!-- <small id="tfNombrevalida" class="form-text text-muted">Verificar su nombre</small> -->
            </div>
            <div class="form-group">
              <label for="cargo">Parentesco:</label>
              <input type="text" id="cargo" class="form-control rounded-0" placeholder="Parentesco"
                formControlName='cargo' readonly>
              <!-- <small id="tfParentescovalida" class="form-text text-muted">Verificar parentesco</small> -->
            </div>
            <div class="form-group">
              <label for="departamento">Departamento:</label>
              <input type="text" id="departamento" class="form-control rounded-0" placeholder="Departamento"
                formControlName='departamento' readonly>
              <!-- <small id="tfDepartamentovalida" class="form-text text-muted">Verificar departamento</small> -->
            </div>
            <div class="row" style="display: flex;">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cumple">Fecha Nacimiento:</label>
                  <ejs-datepicker id="cumple" [format]="format" readonly=true formControlName='cumple' disabled>
                  </ejs-datepicker>
                </div>
              </div>
              <div class="col-md-6">
                <li class="listas">
                  <ejs-checkbox label="Titular de Membresia" formControlName='titular' disabled></ejs-checkbox>
                </li>
              </div>
            </div>
            <div class="form-group">
              <label for="estatus">Departamento:</label>
              <input type="text" id="estatus" class="form-control rounded-0" placeholder="Estatus"
                formControlName='estatus' readonly>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
        <div class="card h-100">
          <div class="card-title mb-1 p-2">
            <h5>Información Identificación del Contacto</h5>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="codigo_extra">Codigo:</label>
              <input type="text" id="codigo_extra" class="form-control rounded-0" placeholder="Codigo"
                formControlName='codigo_extra' readonly>
              <!-- <small id="tfCodigovalida" class="form-text text-muted">Verificar su codigo</small> -->
            </div>
            <div class="form-group">
              <label for="rfid">Codigo RFID:</label>
              <input type="text" id="rfid" class="form-control rounded-0" placeholder="Codigo RFID"
                formControlName='rfid' readonly>
              <!-- <small id="tfCodigoRFIDvalida" class="form-text text-muted">Verificar su codigo RFID</small> -->
            </div>
            <div class="form-group">
              <label for="casillero">Num Casillero:</label>
              <input type="text" id="casillero" class="form-control rounded-0" placeholder="Numero Casillero"
                formControlName='casillero' readonly>
              <!-- <small id="tfNumCasillerovalida" class="form-text text-muted">Verificar su numero de casillero</small> -->
            </div>
            <div class="form-group">
              <label for="membresia">Num Membresia:</label>
              <input type="text" id="membresia" class="form-control rounded-0" placeholder="Numero Membresia"
                formControlName='membresia' readonly>
              <!-- <small id="tfNumMembresiavalida" class="form-text text-muted">Verificar su numero de membresia</small> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
        <div class="card h-100">
          <div class="card-title mb-1 p-2">
            <h5>Información de Domicilio</h5>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="calle">Calle</label>
              <input type="text" id="calle" class="form-control rounded-0" placeholder="Calle"
                formControlName='calle' readonly>
              <!-- <small id="tfcallevalida" class="form-text text-muted">Verificar calle</small> -->
            </div>
            <div class="row" style="display: flex;">
              <div class="col-md-6">
                <label for="num_ext">Numero Ext.</label>
                <input type="text" id="num_ext" class="form-control rounded-0" placeholder="Numero Exterior"
                  formControlName='num_ext' readonly>
                <!-- <small id="tfnumextvalida" class="form-text text-muted">Verificar numero exterior</small> -->
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="num_int">Numero Int.</label>
                  <input type="text" id="num_int" class="form-control rounded-0" placeholder="Numero Interior"
                    formControlName='num_int' readonly>
                  <!-- <small id="tfnumintvalida" class="form-text text-muted">Verificar numero interior</small> -->
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="colonia">Colonia</label>
              <input type="text" id="colonia" class="form-control rounded-0" placeholder="Colonia"
                formControlName='colonia' readonly>
              <!-- <small id="tfcoloniavalida" class="form-text text-muted">Verificar su colonia</small> -->
            </div>
            <div class="form-group">
              <label for="codigo">Codigo Postal</label>
              <input type="text" id="codigo" class="form-control rounded-0" placeholder="Codigo Postal"
                formControlName='codigo' readonly>
              <!-- <small id="tfNumcodigopostalvalida" class="form-text text-muted">Verificar codigo postal</small> -->
            </div>
            <div class="form-group">
              <label for="pais">País</label>
              <input type="text" id="pais" class="form-control rounded-0" placeholder="País"
                formControlName='pais' readonly>
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <input type="text" id="estado" class="form-control rounded-0" placeholder="Estado"
                formControlName='estado' readonly>
            </div>
            <div class="form-group">
              <label for="municipio">Municipio</label>
              <input type="text" id="municipio" class="form-control rounded-0" placeholder="Municipio"
                formControlName='municipio' readonly>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
        <div class="card h-100">
          <div class="card-title mb-1 p-2">
            <h5>Información de Contacto</h5>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="correo">Correo</label>
              <input type="email" class="form-control  rounded-0" id="correo" placeholder="Correo"
                formControlName='correo' readonly>
            </div>
            <div class="form-group">
              <label for="correo_alterno">Correo Alterno</label>
              <input type="email" class="form-control rounded-0" id="correo_alterno" placeholder="Correo alterno"
                formControlName='correo_alterno' readonly>
            </div>
            <div class="row" style="display: flex;">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="tel_movil">Tel. Móvil</label>
                  <input type="text" id="tel_movil" class="form-control rounded-0" placeholder="Teléfono móvil"
                    formControlName='tel_movil' readonly>
                  <!-- <small id="tftelmovilvalida" class="form-text text-muted">Verificar teléfono móvil</small> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="tel_radio">Tel. Radio</label>
                  <input type="text" id="tel_radio" class="form-control rounded-0" placeholder="Teléfono radio"
                    formControlName='tel_radio' readonly>
                  <!-- <small id="tftelradiovalida" class="form-text text-muted">Verificar teléfono de radio</small> -->
                </div>
              </div>
            </div>
            <div class="row" style="display: flex;">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="tel_oficina">Tel. Oficina</label>
                  <input type="text" id="tel_oficina" class="form-control rounded-0" placeholder="Teléfono oficina"
                    formControlName='tel_oficina' readonly>
                  <!-- <small id="tfteloficinavalida" class="form-text text-muted">Verificar teléfono de oficina</small> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="tel_fax">Tel. Fax</label>
                  <input type="text" id="tel_fax" class="form-control rounded-0" placeholder="Teléfono fax"
                    formControlName='tel_fax' readonly>
                  <!-- <small id="tftelfaxvalida" class="form-text text-muted">Verificar teléfono de fax</small> -->
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="tel_casa">Tel. Casa:</label>
              <input type="text" id="tel_casa" class="form-control rounded-0" placeholder="Teléfono Casa"
                formControlName='tel_casa' readonly>
              <!-- <small id="tftelcasavalida" class="form-text text-muted">Verificar teléfono de casa</small> -->
            </div>
            <!-- <div class="form-group">
              <label for="tftwitter">Twitter</label>
              <input type="text" id="tftwitter" class="form-control rounded-0" placeholder="Twitter">
              <small id="tftwittervalida" class="form-text text-muted">Verificar twitter</small>
            </div>
            <div class="form-group">
              <label for="tfwhatsapp">WhatsApp</label>
              <input type="text" id="tfwhatsapp" class="form-control rounded-0" placeholder="WhatsApp">
              <small id="tfwhatsappvalida" class="form-text text-muted">Verificar whatsapp</small>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </form>
  <ejs-grid [dataSource]='data' gridLines='Both' height='200px' [rowHeight]='20' (rowSelected)="onRowSelected($event)">
    <e-columns>
      <e-column field='id' headerText='id' width='0'></e-column>
      <e-column field='id_clb_membresia' headerText='id membresia' width='0'></e-column>
      <e-column field='codigo_extra' headerText='Codigo Extra' width='150'></e-column>
      <e-column field='nombre' headerText='Nombre' width='250'></e-column>
      <e-column field='cargo' headerText='Parentesco' width='150'></e-column>
      <e-column field='departamento' headerText='Departamento' width='150'></e-column>
      <e-column field='estatus' headerText='Estatus' width='150'></e-column>
    </e-columns>
  </ejs-grid>
</div>

