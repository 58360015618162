import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@syncfusion/ej2-base';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-charts';
import { ToolbarSettingsModel } from '@syncfusion/ej2-angular-dropdowns';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { SelectionSettingsModel } from '@syncfusion/ej2-dropdowns';
import { ToolbarService } from '@syncfusion/ej2-angular-grids';
import { RichTextEditor } from '@syncfusion/ej2-richtexteditor';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-tablero2',
  templateUrl: './tablero2.component.html',
  styleUrls: ['./tablero2.component.scss']
})
export class Tablero2Component implements OnInit {
  public aspectRatio: any = 100 / 80;
  public cellSpacing: number[] = [10, 10];
  public width: string = Browser.isDevice ? '100%' : '60%';
  public data: any;
  public marker: Object = {
    visible: true,
    width: 10,
    height: 10,
    dataLabel: {
      visible: false,
      position: 'Top',
      font: {
        size: '16',
        fontWeight: 'normal'
      },
      border: { width: 1, color: 'white' },
      margin: { left: 5, right: 5, top: 2, bottom: 2 },
      //template: '<div style="font-size: 16px;">${point.y}</div>'
    }
  };
  public chartArea: Object = { border: { width: 0 } };
  public enableAnimation: boolean = true;
  public legendSettings: Object = { visible: true };
  public tooltip: Object = { enable: true, format: '${point.x} : <b>${point.y}</b>' };
  //public tooltip: Object = { enable: true };

  //===============================================================================================
  // Configuracion de Ejes
  //===============================================================================================
  public primaryXAxis: Object = {
    valueType: 'Category',
    interval: 1,
    labelIntersectAction: 'Rotate45',
    majorGridLines: { width: 0 },
    labelStyle: {
      fontFamily: 'Roboto',
      fontStyle: 'medium',
      size: '14px'
    }
  };

  public primaryYAxis: Object = {
    labelFormat: 'n2',
    rangePadding: 'None',
    //minimum: 0,
    //maximum: 100,
    //interval: 20,
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 }
  };

  public tooltipRender(args: ITooltipRenderEventArgs): void {
    let textCol = args.text.split(":");
    let str1 = textCol[1].replace('<b>', '');
    let str2 = str1.replace('</b>', '');
    var value = parseFloat((str2).replace(',', ''));
    args.text = textCol[0] + ": <b>" + (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</b>';
  };
  //===============================================================================================


  constructor(
    private route: ActivatedRoute,
    private formBuilder:FormBuilder
  ) { }

  ngOnInit(): void {
    this.route.data
      .subscribe((resp: { tablero2: any[] }) => {
        this.data = JSON.parse(JSON.stringify(resp.tablero2)).respuesta;
        //console.log(resp);
        //console.log(this.data);
      });
  }


  //============================================================================================
  // Lista para traspasar informacion izquierda <=> derecha
  //============================================================================================
  public dataA: { [key: string]: Object }[] = [
    { Name: 'Australia', Code: 'AU' },
    { Name: 'Bermuda', Code: 'BM' },
    { Name: 'Canada', Code: 'CA' },
    { Name: 'Cameroon', Code: 'CM' },
    { Name: 'Denmark', Code: 'DK' },
    { Name: 'France', Code: 'FR' },
    { Name: 'Finland', Code: 'FI' },
    { Name: 'Germany', Code: 'DE' },
    { Name: 'Hong Kong', Code: 'HK' }
  ];
  public dataB: { [key: string]: Object }[] = [
    { Name: 'India', Code: 'IN' },
    { Name: 'Italy', Code: 'IT' },
    { Name: 'Japan', Code: 'JP' },
    { Name: 'Mexico', Code: 'MX' },
    { Name: 'Norway', Code: 'NO' },
    { Name: 'Poland', Code: 'PL' },
    { Name: 'Switzerland', Code: 'CH' },
    { Name: 'United Kingdom', Code: 'GB' },
    { Name: 'United States', Code: 'US' }
  ];
  public fields: FieldSettingsModel = { text: 'Name' };
  public toolbarSettings: ToolbarSettingsModel = { items: ['moveTo', 'moveFrom', 'moveAllTo', 'moveAllFrom'] };
  public noRecordsTemplate = '<div class= "e-list-nrt"><span>NO DATA AVAILABLE</span></div>'

  public actionComplete(args) {
    switch (args.eventName) {
      case "moveTo":
        console.log('moveTo');
        console.log(args.items);
        break;
      case "moveFrom":
        console.log('moveFrom');
        console.log(args.items);
        break;
      case "moveAllTo":
        console.log('moveAllTo');
        console.log(args.items);
        break;
      case "moveAllFrom":
        console.log('moveAllFrom');
        console.log(args.items);
        break;
    }
  }
  //============================================================================================
  // Multiseleccion de una lista
  //============================================================================================
  @ViewChild("lstMultiseleccion") lstMultiseleccion: any;
  public dataCheckbox: Object = [
    { text: 'Do Meditation', id: '1' },
    { text: 'Go Jogging', id: '2' },
    { text: 'Buy Groceries', id: '3' },
    { text: 'Pay Phone bill', id: '4' },
    { text: 'Play Football with your friends', id: '5' },
  ];
  public fieldsCheckbox: Object = { text: 'text', id: 'id' };
  public getMultiseleccionItems(): void {
    console.log(this.lstMultiseleccion.getSelectedItems().data);
  }
  //============================================================================================
  // Configuracion de Fecha
  //============================================================================================
  public format: string = "dd/MM/yyyy";
  //============================================================================================
  // Data para Combo box
  //============================================================================================
  public dataCombobox: string[] = ['Cricket', 'Football', 'Rugby', 'Snooker', 'Tennis'];
  // ===============================================================================================
  // Configuracion para Editor de Texto
  // ===============================================================================================
  @ViewChild('texto') texto: RichTextEditor;
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'Formats', 'Alignments', '|', 'CreateLink'
    ],
    type: 'MultiRow'
  };
  public imageSettings: object = { saveFormat: "Base64" };
  public iframe: object = { enable: true };
  public height: number = 150;
  public txtFocus():void {
    this.texto.toolbarSettings.enable = true;
  }
  public txtBlur():void {
    this.texto.toolbarSettings.enable = false;
  }
  // ===========================================================================================
  // Configuracion para Timer
  // ===========================================================================================
  public laHora: Date = new Date();
  public formatString: string = 'HH:mm';
  public interval: number = 60;
  // ===========================================================================================
  // Configuracion Radio Butom
  // ===========================================================================================
  public dataRadioButom: any = {'opcionRadio': false};
  public frmRadioButom: FormGroup = this.formBuilder.group(this.dataRadioButom);
  public getOpcionesItems(){
    console.log(this.frmRadioButom.value);
  }
  // ===========================================================================================
  // Configuracion Check Box
  // ===========================================================================================
  public dataCheckBox: any = {'opcion_a': false, 'opcion_b': false, 'opcion_c': false};
  public frmCheckBox: FormGroup = this.formBuilder.group(this.dataCheckBox);
  
  public getMultiOpcionesItems(){
    console.log(this.frmCheckBox.value);
  }
}
