import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { environment } from 'src/environments/environment';
import { ReservacionService } from './services/reservacion.service';
import { isNullOrUndefined as isNOU } from '@syncfusion/ej2-base';
import {
  SelectEventArgs,
  TabComponent,
} from '@syncfusion/ej2-angular-navigations';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdlMembresiaReservacion } from './models/MdlMembresiaReservacion';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { TextBox } from '@syncfusion/ej2-angular-inputs';
import { isNullOrUndefined } from 'util';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-reservacion',
  templateUrl: './reservacion.component.html',
  styleUrls: ['./reservacion.component.scss'],
})
export class ReservacionComponent implements OnInit {
  public usuario: MdlUsuario;
  public id_clb_horario_seccion: any;
  public id_contacto: number = -1;
  public nombre_contacto: String;
  public id_clb_seccion_division: number = -1;
  public entradavariable: Date;
  public salidavariable: Date;
  public enlinea: boolean;

  //CONTACTOS
  public v_id_contacto: number = -1;
  public v_nombrecontacto: string = '';
  // SECCION
  public v_id_seccion: number = -1;
  public v_nombreseccion: string = '';
  // DIVISION
  public v_id_division: number = -1;
  public v_nombredivision: string = '';
  // HORARIOS
  public v_id_instructor: number = null;
  public v_nombreinstructor: string = '';
  public v_id_horarioseleccionado: number = -1;
  public v_horarioentrada: Date;
  public v_horariosalida: Date;
  public v_enlinea: boolean = false;

  //DATA DE LOS DROPDOWNLIST
  private data_seccion: any;
  private data_division: any;
  private data_horario: any;

  public fields_seccion: Object = { text: 'descripcion', value: 'id' };
  public fields_division: Object = { text: 'descripcion', value: 'id' };
  public fields_horario: Object = { text: 'descripcion', value: 'id' };

  public showbutton = false;
  public show = false;
  //PARA GUARDAR LA INFORMACION QUE CONTIENE EL HORARIO
  private data_contacto: any;
  public formulario: FormGroup = new FormGroup({});

  @ViewChild('horarios') horarios: DropDownListComponent;
  @ViewChild('divisiones') divisiones: DropDownListComponent;
  @ViewChild('secciones') secciones: DropDownListComponent;
  @ViewChild('contacto') contacto: TextBox;
  @ViewChild('contactoname') contactoname: TextBox;
  @ViewChild('instructor') instructor: TextBox;
  @ViewChild('instructorname') instructorname: TextBox;
  @ViewChild('entrada') entrada: TextBox;
  @ViewChild('salida') salida: TextBox;
  @ViewChild('linea') linea: TextBox;
  @ViewChild('division') division: TextBox;
  @ViewChild('dlgReservacion') dlgReservacion: DialogComponent;
  @ViewChild('tab') tab: TabComponent;

  public data: any;
  public columns: any;
  public headerText: Object[];

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private reservacionService: ReservacionService
  ) {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.http
      .get<any>(
        `${environment.base_url}/membresia/contacto/cargatbcontactos/${this.usuario.id_clb_membresia}`
      )
      .subscribe((resp) => {
        this.data = resp.respuesta.dataSource;
        this.columns = resp.respuesta.columns;
      });
    this.cargaListBox();
    this.formulario = this.formBuilder.group(new MdlMembresiaReservacion());
  }

  cargaListBox() {
    this.reservacionService.seccion().subscribe((resp) => {
      this.data_seccion = resp.respuesta;
    });
  }
  onChangeSeccion(args): void {
    this.reservacionService
      .division2(Number(this.secciones.value))
      .subscribe((resp) => {
        this.data_division = resp.respuesta.divisiones;
        this.data_horario = resp.respuesta.horarios;
      });
      this.v_id_seccion = Number(this.secciones.value);
      this.v_nombredivision = this.secciones.text;
  }

  onChangeDivision(args): void {
    console.log(this.divisiones.text);
    this.v_id_division = Number(this.divisiones.value);
    this.v_nombredivision = this.divisiones.text;
  }

  public onRowSelected(args: any) {
    if (args.data.estatus == 'SUSPENDIDO') {
      DialogUtility.alert({
        title:
          '<span class="dialogo-titulo-error">Sistema<i style="color: #fff" class="fas fa-ban"></i></span>',
        content: 'El usuario esta suspendido, no puede realizar reservaciones',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
      });
      this.showbutton = false;
      this.show = false;
    } else {
      this.showbutton = true;
      this.v_id_contacto = args.data.id;
      this.v_nombrecontacto = args.data.nombre;
      this.cargaContacto(args.data.id);
    }
  }
  cargaContacto(id: number) {
    this.http
      .get<any>(
        `${environment.base_url}/membresia/contacto/cargacontacto/${id}`
      )
      .subscribe((resp) => {});
  }

  cargaInfoHorarios(id: any) {

  }
  onCambios(args): void {
    this.http
      .get<any>(
        `${environment.base_url}/membresia/reservacion/cargainfohorarios/${this.horarios.value}`
      )
      .subscribe((resp) => {
        this.data_contacto = resp.respuesta;
        this.v_id_instructor = resp.respuesta.id_crm_instructor;
        this.v_nombreinstructor = resp.respuesta.nombre_instructor;
        this.v_id_horarioseleccionado = Number(this.horarios.value);
        this.v_horarioentrada = resp.respuesta.entrada;
        this.v_horariosalida = resp.respuesta.salida;
        this.v_enlinea = resp.respuesta.enlinea;
      });
    console.log(this.data_contacto);
    // this.v_id_instructor = this.data_contacto.id_crm_instructor;
    // this.v_nombreinstructor = this.data_contacto.nombre_instructor;
    // this.v_id_horarioseleccionado = Number(this.horarios.value);
    // this.v_horarioentrada = this.data_contacto.entrada;
    // this.v_horariosalida = this.data_contacto.salida;
    // this.v_enlinea = this.data_contacto.enlinea;

  }

  ngOnInit(): void {
    this.headerText = [
      { text: 'Seccion' },
      { text: 'División' },
      { text: 'Horario' },
      { text: 'Confirmación' },
    ];
  }
  btnCargarReservacion() {
    this.dlgReservacion.show();
  }

  //FORMULARIO RESERVACIONES
  public btnClicked(e: any): void {
    // console.log(e.target.id);
    console.log('ID SECCION =', this.secciones.value);
    switch (e.target.id) {
      case 'division':
        if (this.secciones.value <= 0 || this.secciones.value == null) {
          document.getElementById('err1').innerText = '*Seleccione una seccion';
        } else {
          this.tab.enableTab(1, true);
          this.tab.enableTab(0, false);
          document.getElementById('err1').innerText = '';
          document.getElementById('err2').innerText = '';
        }
        break;
      case 'horarios':
        // console.log('ID DIVISION =', this.divisiones.value);
        if (this.divisiones.value <= 0 || this.secciones.value == null) {
          document.getElementById('err2').innerText =
            '*Seleccione una division';
        } else {
          this.tab.enableTab(2, true);
          this.tab.enableTab(1, false);
          document.getElementById('err2').innerText = '';
        }
        break;
      case 'confirmacion':
        // console.log('ID HORARIO =', this.horarios.value);
        if (this.horarios.value <= 0 || this.horarios.value == null) {
          document.getElementById('err3').innerText = '*Seleccione un horario';
        } else {
          this.formulario = this.fb.group({
            id: [-1],
            id_clb_membresia_contacto: [this.v_id_contacto],
            id_clb_seccion_division: [this.v_id_division],
            id_crm_instructor: [this.v_id_instructor],
            entrada: [this.v_horarioentrada],
            salida: [this.v_horariosalida],
            enlinea: [this.v_enlinea]
          });
          console.log(this.v_id_contacto);
          console.log(this.v_nombrecontacto);
          console.log(this.v_id_seccion);
          console.log(this.v_nombreseccion);
          console.log(this.v_id_division);
          console.log(this.v_nombredivision);
          console.log(this.v_id_instructor);
          console.log(this.v_nombreinstructor);
          console.log(this.v_id_horarioseleccionado);
          console.log(this.v_horarioentrada);
          console.log(this.v_horariosalida);
          console.log(this.v_enlinea);
          console.log('importante:', this.v_id_contacto , this.v_id_division , this.v_id_instructor , this.v_horarioentrada, this.v_horariosalida, this.v_enlinea);
          this.tab.enableTab(3, true);
          this.tab.enableTab(2, false);
          document.getElementById('err3').innerText = '';
        }

        break;
      case 'regresararea':
        this.tab.enableTab(0, true);
        this.tab.select(0);
        this.tab.enableTab(1, false);
        break;
      case 'regresardivision':
        this.cargaInfoHorarios(this.horarios.value);
        this.tab.enableTab(1, true);
        this.tab.select(1);
        this.tab.enableTab(2, false);
        break;
      case 'regresarhorario':
        this.cargaInfoHorarios(this.horarios.value);
        this.tab.enableTab(2, true);
        this.tab.select(2);
        this.tab.enableTab(3, false);
        break;
    }
  }
  public tabSelecting(e: SelectEventArgs): void {
    if (e.isSwiped) {
      e.cancel = true;
    }
  }
  public btnGuardarReservacion(): void {
    this.reservacionService
      .guardar(this.formulario.value as MdlMembresiaReservacion)
      .subscribe((resp) => {
        let respuesta = JSON.parse(JSON.stringify(resp));
        if (respuesta.numero < 0) {
          DialogUtility.alert({
            title:
              '<span class="dialogo-titulo-error">' +
              respuesta.titulo +
              ' <i style="color: #fff" class="fas fa-ban"></i></span>',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
        } else {
          DialogUtility.alert({
            title:
              '<span style="color: #28a745">' +
              respuesta.titulo +
              ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
            this.tab.enableTab(0, true);
            this.tab.enableTab(1, false);
            this.tab.enableTab(2, false);
            this.tab.enableTab(3, false);
            this.tab.select(0);
        }
      });
  }
}
