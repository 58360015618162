import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CalendarioComponent } from './SIusuario/calendario/calendario.component';
import { GeneralComponent } from './general/general.component';
import { IntegrantesComponent } from './integrantes/integrantes.component';
import { EstadocuentaComponent } from './estadocuenta/estadocuenta.component';
import { OpenpayComponent } from './openpay/openpay.component';
import { TerminosComponent } from './terminos/terminos.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { ConfirmaPagoComponent } from './confirma-pago/confirma-pago.component';
import { OcupacionComponent } from './ocupacion/ocupacion.component';

@NgModule({
  declarations: [
    CalendarioComponent,
    GeneralComponent,
    IntegrantesComponent,
    EstadocuentaComponent,
    OpenpayComponent,
    TerminosComponent,
    PoliticasComponent,
    ConfirmaPagoComponent,
    OcupacionComponent
  ],

  imports: [
    SharedModule
  ],
  providers: [

  ],
  //===============================================================================================
  // La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
  // html de cada componente. Esto para los componentes importados de terceros SyncFusion.
  //===============================================================================================
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  //===============================================================================================
})
export class PagesModule { }
