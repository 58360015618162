<div class="row clsTitulo">
    <div class="col-10">
        <h2>Tablero de Informacion</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="far fa-chart-bar"></i>
        </div>
    </div>
</div>

<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">

        <div *ngFor="let grupo of data" class="jumbotron jumbotron-fluid">
            <div class="container">
                <h3 style="color: #e91e63" [innerHtml]="grupo.descripcion"></h3>
                <p [innerHtml]="grupo.observaciones"></p>

                <div *ngFor="let detalle of grupo.detalle">
                    <h5 class="display-6" [innerText]="detalle.descripcion"></h5>
                    <p [innerHtml]="detalle.observaciones | safeHtml"></p>
                    <div class="card-columns">
                        <div *ngFor="let item of detalle.dato" class="card" style="width: 18rem;">
                            <div class="card-body">
                                <h5 class="card-title" [innerHtml]="item.descripcion | safeHtml"></h5>
                                <p class="card-text" [innerHtml]="item.observaciones | safeHtml"></p>
                                <a [routerLink]="['/tablero', 'tablero2']" [queryParams]="{id: detalle.id, id2: item.id}" class="btn btn-primary">
                                    Ir Tablero <i class="fas fa-globe"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
    </div>
</div>