import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import {
  GridComponent,
  SelectionSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { EstadocuentaService } from './services/estadocuenta.service';

@Component({
  selector: 'app-estadocuenta',
  templateUrl: './estadocuenta.component.html',
  styleUrls: ['./estadocuenta.component.scss'],
})
export class EstadocuentaComponent implements OnInit {
  public AdeudoTabla: any;
  public ImporteTotal: number = 0;
  public usuario: MdlUsuario;

  @ViewChild('adeudos') adeudos: GridComponent;
  @ViewChild('importefinal') importefinal: ElementRef;
  @ViewChild('chkHistorial') chkHistorial: CheckBoxComponent;
  @ViewChild('checkbox') checkbox: CheckBoxComponent;
  public id: number = -1;
  public selectionOptions: SelectionSettingsModel = {
    type: 'Multiple',
    enableSimpleMultiRowSelection: true,
  };
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private Services: EstadocuentaService,
    private router: Router
  ) {
    // id de la membresia localStorage se debe importar modelo en consola
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.Services.getTabla(
      this.usuario.id_clb_membresia,
      false
    ).subscribe((resp) => {
      this.AdeudoTabla = resp.respuesta;
    });
  }

  ngOnInit(): void {}

  public onRowSelected(args: any) {
    this.ImporteTotal = 0.0;
    let registros: any = this.adeudos.getSelectedRecords();
    registros.forEach((registro) => {
      this.ImporteTotal += registro.importe;
    });
    this.importefinal.nativeElement.value = this.ImporteTotal.toPrecision(6);
  }

  /**
   *
   * @param args
   */
  checkboxChange(args: any) {
  
  }

  /**
   *
   * @param args
   */
  public rowDeselected(args: any): void {
    this.ImporteTotal = 0.0;
    let registros: any = this.adeudos.getSelectedRecords();
    registros.forEach((registro) => {
      this.ImporteTotal += registro.importe;
    });
    this.importefinal.nativeElement.value = this.ImporteTotal.toPrecision(6);
  }

  // function to handle the CheckBox change event
  public changeHandler(): void {
    this.checkbox.label = 'CheckBox: ' + this.checkbox.checked;
    this.Services.getTabla(
      this.usuario.id_clb_membresia,
      this.checkbox.checked
    ).subscribe((resp) => {
      this.AdeudoTabla = resp.respuesta;
    });
  }

  btnPagar() {
    let registros: any = this.adeudos.getSelectedRecords();
    let ids: any;
    registros.forEach((registro) => {
      ids += + ',' + registro.id;
    });
    this.Services.getAdeudo(this.usuario.id_clb_membresia, ids).subscribe(
      (resp) => {
        if (resp.numero > 0) {
          this.router.navigate(['pages/openpay', resp.numero]);
        } else {
          DialogUtility.alert({
            title: '<span style="color: #28a745">' + resp.titulo + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        }
      }
    );
  }
}
